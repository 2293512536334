import React from 'react'

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="footer">
        <div className="content has-text-centered ">
          <div className="columns">
            <div className="column is-4">
              <p>&copy; Copyright - Lakeshore Leadership Development</p>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
