import React from 'react'
import { Link } from 'gatsby'
import logo from '../img/logo.png'

const Navbar = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false,
      navBarActiveClass: '',
    }
  }

  toggleHamburger = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active,
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.active
          ? this.setState({
              navBarActiveClass: 'is-active',
            })
          : this.setState({
              navBarActiveClass: '',
            })
      }
    )
  }

  render() {
    return (
      <div>
        <nav
          className="navbar is-transparent"
          role="navigation"
          aria-label="main-navigation"
        >
          <div className="container">
            <div className="navbar-brand">
              <Link to="/" className="navbar-item" title="Logo">
                <img src={logo} alt="Lakeshore Leadership" width="325" />
              </Link>
              {/* Hamburger menu */}
              <div
                className={`navbar-burger burger ${this.state.navBarActiveClass}`}
                data-target="navMenu"
                onClick={() => this.toggleHamburger()}
                style={{height: 'auto'}}
              >
                <span />
                <span />
                <span />
              </div>
            </div>
            
          </div>
        </nav>
        <div
        id="navMenu"
        className={`navbar-menu ${this.state.navBarActiveClass}`}
      >
        <div className="container">
        <div className="navbar-start has-text-centered">
          <Link className="navbar-item" to="/">
            Home
          </Link>
          <Link className="navbar-item" to="/developmental-coaching/">
            Developmental Coaching
          </Link>
          <Link className="navbar-item" to="/coaching-applications/">
            Coaching Applications
          </Link>
          <Link className="navbar-item" to="/who-is-kyle-williams/">
            Who is Kyle Williams?
          </Link>
          <Link className="navbar-item" to="/how-to-get-started/">
            How to get started
          </Link>
          <Link className="navbar-item" to="/client-tools/">
            Client Tools
          </Link>
          <Link className="navbar-item" to="/contact/">
            Contact
          </Link>
        </div>
        </div>
      </div>
    </div>
    )
  }
}

export default Navbar
